'use client';

import React, { useState } from 'react';
import { TextField, Checkbox, FormControlLabel, Radio, RadioGroup, IconButton, Accordion, AccordionSummary, AccordionDetails, Select, MenuItem, FormControl, InputLabel, Typography, Box, CircularProgress } from '@mui/material';
import { Offer } from '../../../../shared/models';
import { AdditionalFee } from '../../../../shared/models';
import DeleteIcon from '@mui/icons-material/Delete';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import SmallCustomCalendar from '../../components/small_custom_calendar';
import { collection } from 'firebase/firestore';
import { addDoc } from 'firebase/firestore';
import { db } from '../../lib/firebase/app';
import dayjs from 'dayjs';
import ErrorPopup from '../../components/ErrorPopup';


export default function OfferComponent({
    venueId,
    guestCount,
    defaultPrice,
    defaultDate = new Date(),
    defaultStartTime = '7:00 PM',
    defaultEndTime = '10:00 PM',
    defaultFees,
    matchMakerSessionId,
    sectionNameToId,
    onSuccess,
}: {
    venueId: string;
    matchMakerSessionId: string;
    guestCount: number;
    defaultPrice: number;
    defaultDate?: Date;
    defaultStartTime?: string;
    defaultEndTime?: string;
    defaultFees: AdditionalFee[];
    sectionNameToId: { name: string; id: string }[];
    onSuccess?: (offer: Offer) => void;
}) {
    const [offerType, setOfferType] = useState<'exactPrice' | 'noMinimumSpend'>('exactPrice');
    const [total, setTotal] = useState(defaultPrice);
    const [fees, setFees] = useState<AdditionalFee[]>(defaultFees);
    const [chargeDeposit, setChargeDeposit] = useState(false);
    const [depositAmount, setDepositAmount] = useState<number>(100); // Default to $100 instead of percentage
    const [date, setDate] = useState<Date | null>(defaultDate);
    const [dateAccordionExpanded, setDateAccordionExpanded] = useState(!defaultDate);
    const [errors, setErrors] = useState({
        date: '',
        time: '',
        section: ''
    });
    const [showPriceBreakdown, setShowPriceBreakdown] = useState(false);
    const [isSaving, setIsSaving] = useState(false);
    const [selectedSectionId, setSelectedSectionId] = useState<string>(
        sectionNameToId.length === 1 ? sectionNameToId[0].id : ''
    );
    const [offerMessage, setOfferMessage] = useState('');
    const [error, setError] = useState<{ isError: boolean, message: string, details?: string }>({
        isError: false,
        message: '',
        details: ''
    });

    const handleAddFee = () => {
        setFees([...fees, { type: 'percentage', name: '', value: 0 }]);
    };

    const handleRemoveFee = (index: number) => {
        setFees(fees.filter((_, i) => i !== index));
    };

    const handleFeeChange = (index: number, field: keyof AdditionalFee, value: string | number) => {
        const newFees = [...fees];
        if (field === 'value') {
            const numValue = parseFloat(value.toString()) || 0;
            newFees[index] = { ...newFees[index], [field]: numValue };
        } else if (field === 'type') {
            newFees[index] = { ...newFees[index], type: value as 'percentage' | 'flat' };
        } else {
            newFees[index] = { ...newFees[index], [field]: value };
        }
        setFees(newFees);
    };

    const validateFees = () => {
        const cleanedFees = fees.filter(fee =>
            fee.name.trim() !== '' && fee.value !== 0
        );
        setFees(cleanedFees);
        return cleanedFees;
    };

    const calculateTotalWithFees = () => {
        const baseTotal = total;
        const feesTotal = fees.reduce((sum, fee) => {
            return sum + (fee.type === 'percentage' ?
                baseTotal * (fee.value / 100) :
                fee.value);
        }, 0);
        return baseTotal + feesTotal;
    };

    const calculateDepositAmount = () => {
        if (offerType === 'noMinimumSpend') {
            return (guestCount * 2.5).toFixed(2);
        }
        return chargeDeposit ? depositAmount.toFixed(2) : '0.00';
    };

    const generateTimeOptions = () => {
        const times = [];
        for (let hour = 6; hour < 30; hour++) {
            for (let minute = 0; minute < 60; minute += 30) {
                const actualHour = hour % 24;
                const period = actualHour >= 12 ? 'PM' : 'AM';
                const displayHour = actualHour === 0 ? 12 : actualHour > 12 ? actualHour - 12 : actualHour;
                const displayMinute = minute === 0 ? '00' : minute;
                const nextDay = hour >= 24 ? ' ' : '';
                const time = `${displayHour}:${displayMinute} ${period}${nextDay}`;
                times.push(time);
            }
        }
        return times;
    };

    const timeOptions = generateTimeOptions();

    // Find closest time option to the provided time string
    const findClosestTimeOption = (timeStr: string) => {
        // If exact match exists, return it
        if (timeOptions.includes(timeStr)) {
            return timeStr;
        }

        // Try to normalize the time format for comparison (handle edge cases)
        // Extract hour, minute, and period from the time string
        const timeRegex = /(\d+):(\d+)\s?(AM|PM)/i;
        const match = timeStr.match(timeRegex);

        if (!match) {
            // If we can't parse the time, return the first time option
            return timeOptions[0];
        }

        const [, hourStr, minuteStr, periodStr] = match;
        let hour = parseInt(hourStr);
        const minute = parseInt(minuteStr);
        const period = periodStr.toUpperCase();

        // Convert to 12-hour format for comparison
        if (period === 'PM' && hour < 12) {
            hour += 12;
        } else if (period === 'AM' && hour === 12) {
            hour = 0;
        }

        // Find the closest time option by comparing hours and minutes
        return timeOptions.reduce((closest, option) => {
            const optMatch = option.match(timeRegex);
            if (!optMatch) return closest;

            let [, optHourStr, optMinuteStr, optPeriodStr] = optMatch;
            let optHour = parseInt(optHourStr);
            const optMinute = parseInt(optMinuteStr);
            const optPeriod = optPeriodStr.toUpperCase();

            // Convert to 24-hour format for comparison
            if (optPeriod === 'PM' && optHour < 12) {
                optHour += 12;
            } else if (optPeriod === 'AM' && optHour === 12) {
                optHour = 0;
            }

            // Calculate the difference in minutes
            const timeDiff = Math.abs((hour * 60 + minute) - (optHour * 60 + optMinute));
            const closestMatch = closest.match(timeRegex);

            if (!closestMatch) return option;

            let [, closestHourStr, closestMinuteStr, closestPeriodStr] = closestMatch;
            let closestHour = parseInt(closestHourStr);
            const closestMinute = parseInt(closestMinuteStr);
            const closestPeriod = closestPeriodStr.toUpperCase();

            // Convert to 24-hour format for comparison
            if (closestPeriod === 'PM' && closestHour < 12) {
                closestHour += 12;
            } else if (closestPeriod === 'AM' && closestHour === 12) {
                closestHour = 0;
            }

            const closestDiff = Math.abs((hour * 60 + minute) - (closestHour * 60 + closestMinute));

            return timeDiff < closestDiff ? option : closest;
        }, timeOptions[0]);
    };

    // Initialize start and end times with closest matches from timeOptions
    const [startTime, setStartTime] = useState(
        timeOptions.includes(defaultStartTime) ? defaultStartTime : findClosestTimeOption(defaultStartTime)
    );
    const [endTime, setEndTime] = useState(
        timeOptions.includes(defaultEndTime) ? defaultEndTime : findClosestTimeOption(defaultEndTime)
    );

    const getFilteredEndTimeOptions = () => {
        if (!startTime) return timeOptions;
        const startTimeIndex = timeOptions.indexOf(startTime);
        if (startTimeIndex === -1) return timeOptions;
        return timeOptions.slice(startTimeIndex + 1);
    };

    const handleStartTimeChange = (newStartTime: string) => {
        setStartTime(newStartTime);
        const startIndex = timeOptions.indexOf(newStartTime);
        const endIndex = timeOptions.indexOf(endTime);
        if (endIndex <= startIndex) {
            // Set to the next available time option instead of empty string
            const nextTimeIndex = startIndex + 1;
            if (nextTimeIndex < timeOptions.length) {
                setEndTime(timeOptions[nextTimeIndex]);
            } else {
                setEndTime('');
            }
        }
    };

    const handleSubmit = async () => {
        if (offerType === 'exactPrice' && (!total || total <= 0)) {
            setErrors(prev => ({
                ...prev,
                total: 'Please enter a valid amount'
            }));
            return;
        }

        if (!date || !startTime || !endTime || !selectedSectionId) {
            setErrors({
                date: !date ? 'Date is required' : '',
                time: !startTime || !endTime ? 'Start and end time are required' : '',
                section: !selectedSectionId ? 'Section is required' : ''
            });
            return;
        }

        const validatedFees = validateFees();

        setIsSaving(true);

        const offer: Offer = {
            ID: '', // Will be set by Firestore
            MatchMakerSessionID: matchMakerSessionId,
            VenueID: venueId,
            Date: date.toISOString().split('T')[0],
            StartTime: startTime,
            EndTime: endTime,
            IsNoMinimumSpend: offerType === 'noMinimumSpend',
            PriceToCharge: offerType === 'noMinimumSpend' ?
                guestCount * 2.5 : // $2.5 per guest for no minimum spend
                chargeDeposit ?
                    depositAmount : // Only charge deposit amount if charging deposit
                    calculateTotalWithFees(), // Full amount if not charging deposit
            ...(offerType === 'exactPrice' && {
                BasePrice: Math.max(0, total),
                Fees: validatedFees,
                ...(chargeDeposit && { DepositAmount: depositAmount })
            }),
            Message: offerMessage.trim(),
            SectionID: selectedSectionId,
            CreatedAt: dayjs().toISOString(),
        };

        try {
            const docRef = await addDoc(collection(db, 'offers'), offer);
            console.log('Offer successfully written to Firestore');
            onSuccess?.({ ...offer, ID: docRef.id });
        } catch (err) {
            console.error('Error writing offer to Firestore:', err);
            setError({
                isError: true,
                message: 'Failed to save offer',
                details: err instanceof Error ?
                    `${err.message}\n\n${err.stack || ''}` :
                    JSON.stringify(err, null, 2)
            });
        } finally {
            setIsSaving(false);
        }
    };

    const handleCloseError = () => {
        setError({
            isError: false,
            message: '',
            details: ''
        });
    };

    const handleContinue = () => {
        if (!date || !startTime || !endTime || !selectedSectionId) {
            setErrors({
                date: !date ? 'Date is required' : '',
                time: !startTime || !endTime ? 'Start and end time are required' : '',
                section: !selectedSectionId ? 'Section is required' : ''
            });
            return;
        }
        setShowPriceBreakdown(true);
    };

    const handleBack = () => {
        if (showPriceBreakdown) {
            setShowPriceBreakdown(false);
        }
    };

    const totalAmount = calculateTotalWithFees().toFixed(2);

    return (
        <>
            {isSaving && <CircularProgress />}

            <div className="flex justify-center w-full px-2 sm:px-4">
                <div className="max-w-xl w-full rounded-lg">
                    <FormControl component="fieldset" className="w-full space-y-2 sm:space-y-4">
                        {!showPriceBreakdown ? (
                            <>
                                <div className="flex gap-2 sm:gap-4">
                                    <RadioGroup
                                        row
                                        value={offerType}
                                        onChange={(e) => setOfferType(e.target.value as 'exactPrice' | 'noMinimumSpend')}
                                        className="flex justify-between w-full"
                                    >
                                        <FormControlLabel
                                            value="exactPrice"
                                            control={<Radio size="small" sx={{ color: '#E0B1F1' }} />}
                                            label={<span className="text-sm sm:text-base">Exact Price</span>}
                                            className="text-white"
                                        />
                                        <FormControlLabel
                                            value="noMinimumSpend"
                                            control={<Radio size="small" sx={{ color: '#E0B1F1' }} />}
                                            label={<span className="text-sm sm:text-base">No Minimum Spend</span>}
                                            className="text-white"
                                        />
                                    </RadioGroup>
                                </div>
                                <Accordion
                                    expanded={dateAccordionExpanded}
                                    onChange={(_, isExpanded) => setDateAccordionExpanded(isExpanded)}
                                    sx={{
                                        mb: 1,
                                        backgroundColor: 'rgba(255, 255, 255, 0.05)',
                                        color: '#F0D1FF',
                                        borderRadius: '4px !important',
                                        border: errors.date ? '1px solid #ff6b6b' : 'none',
                                        '&:before': { display: 'none' },
                                        '& .MuiAccordionSummary-root': {
                                            minHeight: '40px',
                                            '&.Mui-expanded': {
                                                minHeight: '40px',
                                            }
                                        },
                                        '& .MuiAccordionSummary-content': {
                                            margin: '8px 0',
                                            '&.Mui-expanded': {
                                                margin: '8px 0',
                                            }
                                        }
                                    }}
                                >
                                    <AccordionSummary expandIcon={<ExpandMoreIcon sx={{ color: '#F0D1FF' }} />}>
                                        {date && (
                                            <Typography sx={{ color: '#F0D1FF', opacity: 0.7, fontSize: '14px' }}>
                                                {dayjs(date).format('MM/DD/YYYY')}
                                            </Typography>
                                        )}
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <SmallCustomCalendar
                                            selectedDate={date || new Date()}
                                            onDateChange={(newDate) => {
                                                setDate(newDate);
                                                setDateAccordionExpanded(false);
                                                if (errors.date) setErrors(prev => ({ ...prev, date: '' }));
                                            }}
                                            minDate={new Date()}
                                        />
                                    </AccordionDetails>
                                </Accordion>

                                <Box sx={{ display: 'flex', gap: 1, mb: 1 }}>
                                    <FormControl size="small" fullWidth error={!!errors.time}>
                                        <InputLabel sx={{ color: '#F0D1FF', fontSize: '0.875rem' }}>Start*</InputLabel>
                                        <Select
                                            value={startTime}
                                            onChange={(e) => handleStartTimeChange(e.target.value)}
                                            required
                                            sx={{
                                                bgcolor: 'rgba(255, 255, 255, 0.05)',
                                                color: '#F0D1FF',
                                                height: '40px',
                                                '& .MuiSelect-select': {
                                                    padding: '8px 14px',
                                                    fontSize: '0.875rem'
                                                }
                                            }}
                                        >
                                            {timeOptions.map(time => (
                                                <MenuItem key={time} value={time}>{time}</MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>

                                    <FormControl size="small" fullWidth error={!!errors.time}>
                                        <InputLabel sx={{ color: '#F0D1FF', fontSize: '0.875rem' }}>End*</InputLabel>
                                        <Select
                                            value={endTime}
                                            onChange={(e) => setEndTime(e.target.value)}
                                            required
                                            disabled={!startTime}
                                            sx={{
                                                bgcolor: 'rgba(255, 255, 255, 0.05)',
                                                color: '#F0D1FF',
                                                height: '40px',
                                                '& .MuiSelect-select': {
                                                    padding: '8px 14px',
                                                    fontSize: '0.875rem'
                                                }
                                            }}
                                        >
                                            {getFilteredEndTimeOptions().map(time => (
                                                <MenuItem key={time} value={time}>{time}</MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Box>

                                {offerType === 'exactPrice' ? (
                                    <>
                                        <TextField
                                            label="Base Amount"
                                            type="number"
                                            value={total}
                                            onChange={(e) => setTotal(parseFloat(e.target.value))}
                                            fullWidth
                                            size="small"
                                            sx={{
                                                '& .MuiOutlinedInput-root': {
                                                    color: 'white',
                                                    '& fieldset': { borderColor: '#E0B1F1' },
                                                },
                                                '& .MuiInputLabel-root': { color: '#E0B1F1' }
                                            }}
                                        />
                                        <div className="space-y-2 sm:space-y-4">
                                            {fees.map((fee, index) => (
                                                <div key={index} className="flex flex-col sm:flex-row gap-2 sm:gap-4">
                                                    <div className="flex-1">
                                                        <TextField
                                                            label="Fee Name"
                                                            value={fee.name}
                                                            onChange={(e) => handleFeeChange(index, 'name', e.target.value)}
                                                            fullWidth
                                                            size="small"
                                                            sx={{
                                                                '& .MuiOutlinedInput-root': {
                                                                    color: 'white',
                                                                    '& fieldset': { borderColor: '#E0B1F1' },
                                                                },
                                                                '& .MuiInputLabel-root': { color: '#E0B1F1' }
                                                            }}
                                                        />
                                                    </div>
                                                    <div className="flex items-center gap-2 sm:gap-4">
                                                        <TextField
                                                            label="Value"
                                                            type="number"
                                                            value={fee.value}
                                                            onChange={(e) => handleFeeChange(index, 'value', parseFloat(e.target.value))}
                                                            size="small"
                                                            className="w-24 sm:w-32"
                                                            sx={{
                                                                '& .MuiOutlinedInput-root': {
                                                                    color: 'white',
                                                                    '& fieldset': { borderColor: '#E0B1F1' },
                                                                },
                                                                '& .MuiInputLabel-root': { color: '#E0B1F1' }
                                                            }}
                                                        />
                                                        <RadioGroup
                                                            row
                                                            value={fee.type}
                                                            onChange={(e) => handleFeeChange(index, 'type', e.target.value)}
                                                            className="flex-shrink-0"
                                                        >
                                                            <FormControlLabel
                                                                value="percentage"
                                                                control={<Radio size="small" sx={{ color: '#E0B1F1' }} />}
                                                                label={<span className="text-sm">%</span>}
                                                                className="text-white"
                                                            />
                                                            <FormControlLabel
                                                                value="flat"
                                                                control={<Radio size="small" sx={{ color: '#E0B1F1' }} />}
                                                                label={<span className="text-sm">$</span>}
                                                                className="text-white"
                                                            />
                                                        </RadioGroup>
                                                        <IconButton
                                                            size="small"
                                                            onClick={() => handleRemoveFee(index)}
                                                            sx={{ color: '#E0B1F1' }}
                                                            className="flex-shrink-0"
                                                        >
                                                            <DeleteIcon fontSize="small" />
                                                        </IconButton>
                                                    </div>
                                                </div>
                                            ))}
                                            <button
                                                onClick={handleAddFee}
                                                className="mt-2 px-4 py-2 bg-[#E0B1F1] text-[#353644] rounded-lg hover:bg-opacity-90"
                                            >
                                                Add Fee
                                            </button>
                                        </div>

                                        <div className="flex items-center justify-between">
                                            <FormControlLabel
                                                control={<Checkbox checked={chargeDeposit} onChange={(e) => setChargeDeposit(e.target.checked)} sx={{ color: '#E0B1F1' }} />}
                                                label="Charge Only a Deposit to Hold the Space"
                                                className="text-white"
                                            />
                                            {chargeDeposit && (
                                                <TextField
                                                    label="Deposit Amount"
                                                    type="number"
                                                    value={depositAmount}
                                                    size="small"
                                                    sx={{
                                                        width: '120px',
                                                        '& .MuiOutlinedInput-root': {
                                                            color: 'white',
                                                            '& fieldset': { borderColor: '#E0B1F1' },
                                                        },
                                                        '& .MuiInputLabel-root': { color: '#E0B1F1' }
                                                    }}
                                                    onChange={(e) => setDepositAmount(parseFloat(e.target.value))}
                                                />
                                            )}
                                        </div>
                                    </>
                                ) : (
                                    <div className="p-4 bg-[#2c2c54] rounded-lg">
                                        <div className="text-white text-center">
                                            <p className="text-lg">No minimum spend</p>
                                            <p className="text-[#E0B1F1] font-bold mt-2">
                                                We'll charge a refundable deposit of ${(guestCount * 2.5).toFixed(2)}
                                            </p>
                                            <div className="mt-4 text-sm text-gray-300">
                                                <p>We will charge the customer a refundable deposit of ${(guestCount * 2.5).toFixed(2)} to confirm their reservation.</p>
                                                <p className="mt-2">If they do not respect the cancellation policy we will credit you with 85%. If they do show up, we'll refund the customer.</p>
                                            </div>
                                        </div>
                                    </div>
                                )}

                                <FormControl
                                    fullWidth
                                    size="small"
                                    required
                                    error={!!errors.section}
                                    sx={{ mb: 2 }}
                                >
                                    <InputLabel sx={{
                                        color: errors.section ? '#ff6b6b' : '#F0D1FF'
                                    }}>
                                        Select a section
                                    </InputLabel>
                                    <Select
                                        value={selectedSectionId}
                                        onChange={(e) => {
                                            setSelectedSectionId(e.target.value);
                                            if (errors.section) setErrors(prev => ({ ...prev, section: '' }));
                                        }}
                                        sx={{
                                            bgcolor: 'rgba(255, 255, 255, 0.05)',
                                            color: '#F0D1FF',
                                            '& .MuiSelect-select': {
                                                padding: '8px 14px',
                                            },
                                            border: errors.section ? '1px solid #ff6b6b' : 'none',
                                            '&:hover': {
                                                border: errors.section ? '1px solid #ff6b6b' : 'none',
                                            }
                                        }}
                                    >
                                        <MenuItem value="" disabled>
                                            <em>Select a section</em>
                                        </MenuItem>
                                        {sectionNameToId.map(section => (
                                            <MenuItem key={section.id} value={section.id}>
                                                {section.name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                    {errors.section && (
                                        <Typography color="error" variant="caption" sx={{ mt: 0.5 }}>
                                            Please select a section
                                        </Typography>
                                    )}
                                </FormControl>

                                <button
                                    onClick={showPriceBreakdown ? handleSubmit : handleContinue}
                                    className="w-full py-1.5 sm:py-2 mt-3 bg-[#E0B1F1] text-[#353644] rounded-lg hover:bg-opacity-90 text-base sm:text-lg font-semibold transition-all duration-200 hover:shadow-lg"
                                >
                                    {showPriceBreakdown ? 'Send Offer' : 'Continue'}
                                </button>
                            </>
                        ) : (
                            <div className="space-y-4">
                                {offerType === 'exactPrice' ? (
                                    <div className="p-4 bg-[#2c2c54] rounded-lg space-y-2">
                                        <div className="flex justify-between text-white">
                                            <span>Base Amount:</span>
                                            <span>${total.toFixed(2)}</span>
                                        </div>
                                        {fees.filter(fee => fee.name.trim() !== '' && fee.value !== 0).map((fee, index) => (
                                            <div key={index} className="flex justify-between text-white">
                                                <span>{fee.name} ({fee.type === 'percentage' ? `${fee.value}%` : '$'}):</span>
                                                <span>
                                                    ${(fee.type === 'percentage' ?
                                                        total * (fee.value / 100) :
                                                        fee.value).toFixed(2)}
                                                </span>
                                            </div>
                                        ))}
                                        <div className="flex justify-between text-white font-bold pt-2 border-t border-gray-600">
                                            <span>Total:</span>
                                            <span>${totalAmount}</span>
                                        </div>
                                        {chargeDeposit && !isNaN(depositAmount) && depositAmount > 0 && (
                                            <div className="flex justify-between text-[#E0B1F1] font-bold pt-2">
                                                <span>Deposit:</span>
                                                <span>${depositAmount.toFixed(2)}</span>
                                            </div>
                                        )}
                                    </div>
                                ) : (
                                    <div className="p-4 bg-[#2c2c54] rounded-lg">
                                        <div className="text-white text-center">
                                            <p className="text-lg">No minimum spend</p>
                                            <p className="text-[#E0B1F1] font-bold mt-2">
                                                Deposit amount: ${(guestCount * 2.5).toFixed(2)}
                                            </p>
                                            <div className="mt-4 text-sm text-gray-300">
                                                <p>We will charge the customer a deposit of ${(guestCount * 2.5).toFixed(2)} to confirm their place.</p>
                                                <p className="mt-2">If they do not respect the cancellation policy we will credit you with 85%.</p>
                                            </div>
                                        </div>
                                    </div>
                                )}

                                <TextField
                                    multiline
                                    rows={4}
                                    placeholder="Add a description of the offer to the guest. You can send multiple offers for the same inquiry with different options."
                                    value={offerMessage}
                                    onChange={(e) => setOfferMessage(e.target.value)}
                                    fullWidth
                                    sx={{
                                        backgroundColor: 'rgba(255, 255, 255, 0.05)',
                                        '& .MuiOutlinedInput-root': {
                                            color: 'white',
                                            '& fieldset': { borderColor: '#E0B1F1' },
                                            '&:hover fieldset': { borderColor: '#E0B1F1' },
                                            '&.Mui-focused fieldset': { borderColor: '#E0B1F1' },
                                        },
                                        '& .MuiInputLabel-root': { color: '#E0B1F1' }
                                    }}
                                />

                                <div className="flex gap-4">
                                    <button
                                        onClick={handleBack}
                                        className="flex-1 py-2 bg-transparent border border-[#E0B1F1] text-[#E0B1F1] rounded-lg hover:bg-[#E0B1F1] hover:text-[#353644] transition-all duration-200"
                                    >
                                        Back
                                    </button>
                                    <button
                                        onClick={handleSubmit}
                                        className="flex-1 py-2 bg-[#E0B1F1] text-[#353644] rounded-lg hover:bg-opacity-90 font-semibold transition-all duration-200"
                                    >
                                        Send Offer
                                    </button>
                                </div>
                            </div>
                        )}
                    </FormControl>
                </div>
            </div>

            <ErrorPopup
                open={error.isError}
                onClose={handleCloseError}
                title="Offer Error"
                message={error.message}
                details={error.details}
            />
        </>
    );
}
